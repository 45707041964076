import { getParagraph } from 'components/paragraph/paragraph-factory'
import { graphql } from 'gatsby'
import { get, has } from 'lodash'
import React from 'react'
import Layout from 'components/layout/layout'
import Meta from 'components/meta/meta'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import { isUrlAbsolute } from '../util/util'

const DrupalNodeTemplate = ({ location, data }) => {
  const banner = get(data, 'node.relationships.field_banner')
  const siteMetadata = get(data, 'site.meta')
  const pageTitle = get(data, 'node.title', siteMetadata.title)

  const options = {
    decodeEntities: true,
    transform,
  }

  function transform(node, index) {
    if (node.type === 'tag' && node.name === 'a') {
      if (has(node, 'attribs.href')) {
        if (!isUrlAbsolute(node.attribs.href)) {
          node.attribs.href = siteMetadata.drupalUrl + node.attribs.href
        }
        return convertNodeToElement(node, index, transform)
      }
    }
  }

  let mainContent = undefined
  if (has(data, 'node.body.processed')) {
    const drupalUrl = process.env.DRUPAL_URL || 'https://ispe.org'
    let content = get(data, 'node.body.processed')
    content = content.replace(/\/sites\/default\/files/g, drupalUrl + '/sites/default/files')
    content = content.replace(/\/core\/modules\/media/g, drupalUrl + '/core/modules/media')

    mainContent = new ReactHtmlParser(content, options)
  }

  let contentParagraphs = get(data, 'node.relationships.contentParagraphs', [])
  contentParagraphs = contentParagraphs.map(getParagraph)

  return (
    <Layout title={pageTitle} banner={banner} location={location}>
      <Meta site={siteMetadata} title={pageTitle} />

      {mainContent && (
        <section className="">
          <div className="container">
            <div className="row mx-auto">
              <div className="col">{mainContent}</div>
            </div>
          </div>
        </section>
      )}

      {/* Content Paragraphs. */}
      <div className="content-paragraphs">{contentParagraphs}</div>
    </Layout>
  )
}

export default DrupalNodeTemplate

export const pageQuery = graphql`
  query DrupalNodeTemplate($drupal_id: String!) {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        drupalUrl
        author
        twitter
      }
    }
    node: nodeFoundationPage(drupal_id: { eq: $drupal_id }) {
      drupal_internal__nid
      title
      body {
        processed
        summary
      }
      relationships {
        field_banner {
          field_slide_title
          field_slide_content {
            value
            processed
          }
          field_slide_cta {
            title
            url
          }
          field_slide_image {
            title
            alt
          }
          drupal_internal__id
          relationships {
            field_slide_image {
              localFile {
                childImageSharp {
                  # @Todo: Specify a fluid image and fragment
                  # The default maxWidth is 800 pixels
                  fluid(maxWidth: 2000, srcSetBreakpoints: [576, 768, 992, 1200]) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        contentParagraphs: field_content_blocks {
          type: __typename
          ... on paragraph__featured_content_collection {
            ...ParagraphFeaturedContentItemsFragment
          }
          ... on paragraph__icon_collection {
            ...ParagraphIconCollectionFragment
          }
          ... on paragraph__webform_embed {
            ...ParagraphWebformEmbedFragment
          }
        }
      }
    }
  }
`
